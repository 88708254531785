import { useState, useEffect } from 'react'

const useSessionStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        let currentValue

        try {
            currentValue = JSON.parse(sessionStorage.getItem(key) || JSON.stringify(defaultValue))
        } catch (error) {
            currentValue = defaultValue
        }

        return currentValue
    })

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value))
    }, [value, key])

    return [value, setValue]
}

export default useSessionStorage
