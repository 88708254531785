import React from 'react'
import { Household, Thirdparty, Legal, Cyber, Travel } from '../components/elements/illustrations'

export const products = [
    {
        id: 10,
        name: 'Personal liability',
        icon: <Thirdparty />,
        price: 98,
        info: 'Protect yourself from unexpected claims, stay covered!',
        selected: false
    },
    {
        id: 9,
        name: 'Household insurance',
        icon: <Household />,
        price: 98,
        info: 'Secure your home with affordable, effective coverage!',
        selected: false
    },
    {
        id: 8,
        name: 'Legal protection',
        icon: <Legal />,
        price: 98,
        info: 'Because defending your rights can come at a price.',
        selected: false
    },
    {
        id: 5,
        name: 'Tuition fee insurance',
        icon: <Cyber />,
        price: 98,
        info: 'Stay on track—protect your tuition and your future.',
        selected: false
    },
    {
        id: 6,
        name: 'Travel insurance',
        icon: <Travel />,
        price: 98,
        info: 'A weight off your mind, if not off your luggage!',
        selected: false
    },
    {
        id: 7,
        name: 'Cyber protection',
        icon: <Cyber />,
        price: 98,
        info: "We've got your back online, to get you back online.",
        selected: false
    }
]
