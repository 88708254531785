module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de","fr"],"defaultLanguage":"en","siteUrl":"https://quickcover.xyz","redirect":true,"i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","de","fr"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/products/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/produkte/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/produits/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/home/","getLanguageFromPath":true},{"matchPath":"/:lang?/startseite/","getLanguageFromPath":true},{"matchPath":"/:lang?/accueil/","getLanguageFromPath":true},{"matchPath":"/:lang?/contact","getLanguageFromPath":true},{"matchPath":"/:lang?/kontakt","getLanguageFromPath":true},{"matchPath":"/:lang?/contact","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"quick-cover","short_name":"quick-cover-home","start_url":"/","background_color":"#00FFC4","theme_color":"#00FFC4","display":"minimal-ui","icon":"src/images/quick-cover-fav-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"106aad22b7bf62b00a4422591fc92939"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5VTQJGMS","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
