import { useState, useEffect } from "react"

const useLocalStorage = (key, defaultValue) => {
	const [value, setValue] = useState(() => {
		let currentValue

		try {
			currentValue = JSON.parse(localStorage.getItem(key) || Array(defaultValue))
		} catch (error) {
			currentValue = defaultValue
		}

		return currentValue
	})

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value))
	}, [value, key])

	return [value, setValue]
}

export default useLocalStorage